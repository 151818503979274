<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="/app/dashboard">Home</a></li>
      <li class="breadcrumb-item">Clientes</li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Clientes</h1>
    <!-- END page-header -->
    <panel :noButton="true">
      <div
        slot="header"
        class="w-100 d-flex align-items-center justify-content-between"
      >
        <span>Listagem de clientes </span>
        <button
          v-if="checkFuncionalidade(8)"
          v-b-modal.novo-cliente
          class="btn btn-success"
        >
          Novo Cliente
        </button>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :lineNumbers="false"
        :styleClass="'vgt-table striped'"
        :search-options="{ enabled: true, placeholder: 'Pesquisar' }"
        :pagination-options="pageOptions"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'id'">
            {{ props.row.id.toString().padStart(4, '0') }}
          </span>
          <span v-if="props.column.field == 'nome'">
            {{ props.row.nome }}
          </span>
          <span v-if="props.column.field == 'dataCriacao'">
            {{
              props.row.created_at | filterDateTimeUtc('DD/MM/yyyy HH:mm:ss')
            }}
          </span>
          <span v-if="props.column.field == 'acao'">
            <button
              v-if="checkFuncionalidade(11)"
              @click="detalhesCliente(props.row.id)"
              class="btn btn-info"
            >
              Detalhes
            </button>
          </span>
        </template>
      </vue-good-table>
    </panel>
    <modal-cliente :loadItens="loadItens" />
  </div>
</template>

<script>
import axios from 'axios'
import modalCliente from './components/ModalCliente.vue'
import CheckFunc from '../../../mixins/CheckFunc'
export default {
  components: {
    modalCliente
  },
  data() {
    return {
      isLoad: false, // Corrigido para 'isLoad'
      columns: [
        {
          label: 'Cod. Cliente',
          field: 'id'
        },
        {
          label: 'Cliente',
          field: 'nome'
        },
        {
          label: 'Data Criação',
          field: 'dataCriacao'
        },
        {
          label: 'Ações',
          field: 'acao',
          tdClass: 'text-center',
          width: '150px'
        }
      ],
      rows: []
    }
  },
  mixins: [CheckFunc],
  methods: {
    detalhesCliente(id) {
      this.$router.push({ name: 'cliente.formulario', params: { id: id } })
    },
    async loadItens() {
      this.isLoad = true // Corrigido para 'isLoad'
      await axios
        .get('cliente?per_page=99999')
        .then(res => {
          return res.data.dados
        })
        .then(res => {
          this.rows = res.data
          this.isLoad = false // Corrigido para 'isLoad'
        })
    }
  },
  computed: {
    pageOptions() {
      return {
        enabled: true,
        mode: 'records',
        perPage: 10,
        position: 'bottom',
        perPageDropdown: [10, 50, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        jumpFirstOrLast: true,
        firstLabel: 'Primeira Página',
        lastLabel: 'Última Página',
        nextLabel: 'Próxima',
        prevLabel: 'Anterior',
        rowsPerPageLabel: 'Linhas por página',
        ofLabel: 'de',
        pageLabel: 'página', // for 'pages' mode
        allLabel: 'Todos',
        infoFn: params => `Página atual ${params.firstRecordOnPage}`
      }
    }
  },
  mounted() {
    this.loadItens()
  }
}
</script>
